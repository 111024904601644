// const firebaseConfig = import.meta.env?.VITE_FB_JUNO ?? Cypress.env('VITE_FB_JUNO')
const backendUrl = 'https://api-node-wotmvc7swq-ew.a.run.app'
const ddClientToken = import.meta.env?.VITE_DATADOG_CLIENT_TOKEN ?? Cypress.env('VITE_DATADOG_CLIENT_TOKEN')

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAIt35AxAc7GvQnOdgvZ6_Pm3ROA0u2jnM",
  authDomain: "juno-prod-q2-24-test.firebaseapp.com",
  projectId: "juno-prod-q2-24-test",
  storageBucket: "juno-prod-q2-24-test.appspot.com",
  messagingSenderId: "38083431932",
  appId: "1:38083431932:web:bac70ef56b4277c8753679",
  measurementId: "G-DCG8V5T60T"
}

export const BACKEND_URL = backendUrl
export const DD_CLIENT_TOKEN = ddClientToken
